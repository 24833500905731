<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <OperatorManagementWidget></OperatorManagementWidget>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <PaymentWidget></PaymentWidget>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <BotStatusWidget></BotStatusWidget>
      </v-col>
      <v-col cols="12" lg="6">
        <CallHistoryWidget></CallHistoryWidget>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$api.isAuthenticated) {
        next({ name: 'Login' });
      }
    })
  },
  components: {
    PaymentWidget: () => import('@/components/PaymentWidget'),
    BotStatusWidget: () => import('@/components/BotStatusWidget'),
    OperatorManagementWidget: () => import('@/components/OperatorManagementWidget'),
    CallHistoryWidget: () => import('@/components/CallHistoryWidget')
  }
}
</script>